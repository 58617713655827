import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import People from '../images/people.jpg'
// import ThumbsUp from '../images/thumbup.jpg'
// import Fusion from "../images/f-logo.svg"
// import ContactUsMain from "../components/contactusmain"
import HeroMini from "../components/heroSmall"
import ContactUsMain from "../components/contactusmain"


const ContactUs = () => (
  <Layout>
    <Seo title="Contact Us" />
    <HeroMini title="Contact Us"/>
    <main className="section has-text-black">
      <div className="container pb-5">
        <div className="columns">
          <div className="column has-text-black">
{/* 
            <div class="field">
  <label class="label">Name</label>
  <div class="control">
    <input class="input" type="text" placeholder="Text input" />
  </div>
</div>


<div class="field">
  <label class="label">Email</label>
  <div class="control has-icons-left has-icons-right">
    <input class="input is-danger" type="email" placeholder="Email input" value="hello@" />
    <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
    <span class="icon is-small is-right">
      <i class="fas fa-exclamation-triangle"></i>
    </span>
  </div>
  <p class="help is-danger">This email is invalid</p>
</div>


<div class="field">
  <label class="label">Message</label>
  <div class="control">
    <textarea class="textarea" placeholder="Textarea"></textarea>
  </div>
</div>


<div class="field is-grouped">
  <div class="control">
    <button class="button is-link">Send</button>
  </div>
</div> */}


            <iframe title="Fusion Office" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.951697335611!2d-2.104445384314101!3d49.182503179320534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x780dcd5c85c990f8!2sFusion%20Development!5e0!3m2!1sen!2sje!4v1613137306817!5m2!1sen!2sje" width="100%" height="600" frameBorder="0" allowFullScreen="" aria-hidden="false"></iframe>

            {/* <p className="is-size-3 fusionfont">Fusion Development</p>
            <p className="is-size-4">1st Floor, Forum 4,</p>
            <p className="is-size-4">Grenville Street,</p>
            <p className="is-size-4">St Helier.</p>
            <p className="is-size-4">Jersey JE2 4UF</p> */}

          </div>
        </div>
      </div>
    </main>
    <ContactUsMain/>

  </Layout>
)

export default ContactUs;
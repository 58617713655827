import React from "react"

const ContactUsMain = () => (
  <React.Fragment>
  <section style={{backgroundColor:"#00a79d"}} className="hero section has-text-white">
  <div className="hero-body p-0">
    <div className="container">
      <div className="columns is-desktop is-vcentered">      
        <div className="column">
          <h1 className="title content has-text-white">
            Contact Us
          </h1>
          <p className="content pb-5">Come ask our team any questions and we’ll get back to you as soon as we can.</p>
        </div>
        <div className="column has-text-right-desktop has-text-centered-tablet">
          <span id="enquiry" className="title">
            <a href="mailto:enquiries@fusion.je">enquiries@fusion.je</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
  </React.Fragment>
)

export default ContactUsMain;